/* Local Carousel */
import React from 'react';
import styled from '@emotion/styled';
import mobilePhoneBackground from './assets/mobilePhoneBorder.png';

import { devices } from './mediaQueries';

/* Styled Components */
const CarouselWindow = styled.div`
  position: relative;
  height: 99%;
  overflow: hidden;
  padding: 0;
  margin: 0 60px 0 20px;

  background: no-repeat center url(${mobilePhoneBackground});

  ${devices.$tabletPortrait} {
    width: 230px;
    background-size: 202px;
    margin-left: 60px;
  }

  img {
    border-radius: 15px;
    border: 1px solid black;
  }
`;

const CarouselContainer = styled.div`
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  max-width: 175px;
  padding-top: 7px;
`;

const CarouselPane = styled.ul`
  position: relative;
  height: 100%;
  margin: 0;
  list-style: none;
  overflow: hidden;
  transition: 0.5s all ease-in-out;
`;

const CarouselSlide = styled.li`
  position: relative;
  float: left;
  height: 100%;
  padding: 30px 0 0 0;
  width: ${({ childCount }) => `${100 / childCount}%`};
`;

function CarouselImageWindow(props) {
  const { children, current } = props;
  const childCount = React.Children.count(children);

  // Generate unique IDs for each child
  const slideIds = Array(childCount)
    .fill(0)
    .map((_, idx) => `slide-${idx}`);

  const style = {
    left: current ? `-${current * 100}%` : 0,
    width: `${childCount * 100}%`,
    padding: 0
  };

  /* Rendered Tree of Styled Components*/
  return (
    <CarouselWindow>
      <CarouselContainer>
        <CarouselPane current={current} style={style}>
          {React.Children.map(children, (child, index) => (
            <CarouselSlide
              id={slideIds[index]}
              aria-controls={slideIds[index]}
              childCount={childCount}
            >
              {child}
            </CarouselSlide>
          ))}
        </CarouselPane>
      </CarouselContainer>
    </CarouselWindow>
  );
}

CarouselImageWindow.defaultProps = {
  current: 0
};

export default CarouselImageWindow;
