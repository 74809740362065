import React from 'react';
import styled from '@emotion/styled';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  margin-right: 5px;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

const IndicatorControls = styled.div`
  display: flex;
  flex: 1 1 33.33%;
  min-width: 25px;
  align-items: stretch;
  justify-content: center;
`;

const IndicatorButton = styled.span`
  width: 10px;
  height: 10px;
  border: 1px solid #a5afb8;
  border-radius: 10px;
  background-color: ${({ isSelected }) => (isSelected ? '#2e68b9' : '#a5afb8')};
  cursor: ${({ isSelected }) => (isSelected ? 'default' : 'pointer')};
  border-color: ${({ isSelected }) => (isSelected ? '#2e68b9' : 'none')};
`;

function CarouselControls({ count, current, onChange }) {
  return (
    <IndicatorControls>
      {Array(count)
        .fill(0)
        .map((_, index) => (
          <Button key={index} aria-label="Carousel" onClick={() => onChange(index)}>
            <IndicatorButton isSelected={current === index} />
          </Button>
        ))}
    </IndicatorControls>
  );
}

CarouselControls.defaultProps = {
  current: 0
};

export default CarouselControls;
