import React from 'react';
import styled from '@emotion/styled';
import { Display, SubHeading } from './typography';
import { devices } from './mediaQueries';
import Icon, { EXTERNAL } from './Icon';

// Assets
import activity from './assets/activity.svg';
import analytics from './assets/analytics.svg';
import appStore from './assets/appStore.svg';
import device from './assets/device.png';
import googlePlay from './assets/googlePlay.svg';
import info from './assets/info.svg';
import location from './assets/location.svg';
import logo from './assets/logo.svg';

// Styles
const AppStoreLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  a:first-child {
    justify-self: end;
  }
  a:last-child {
    justify-self: start;
  }
`;

const BannerGrid = styled.div`
  background-image: linear-gradient(#112f64, #1471da);
  display: grid;
  padding: 20px 10px;

  ${devices.$tabletPortrait} {
    padding: 30px 40px;
    grid-template-rows: 0fr 1fr;
  }

  ${devices.$desktop} {
    padding: 30px 60px;
    grid-template-rows: 0fr 1fr;
  }
`;

const BannerContent = styled.div`
  display: grid;
  sup {
    font-size: 1.25rem;
    line-height: 1.875rem;
    top: -1em;
  }

  .heading {
    align-self: end;
  }

  .sub-heading {
    align-self: center;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;

  img {
    height: 250px;
    width: 350px;

    ${devices.$mobile} {
      height: 350px;
      width: 450px;
    }

    ${devices.$tabletPortrait} {
      height: 400px;
      width: 500px;
    }
  }
`;

const InfoItemGrid = styled.div`
  display: none;

  ${devices.$tabletPortrait} {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1em;
  }

  ${devices.$desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
  }
`;

const InfoItem = styled.div`
  display: grid;
  grid-template-columns: 0fr 1fr;
  grid-gap: 0.5em;
  align-items: center;
`;

const ImageContent = styled.div`
  display: grid;
  grid-template-rows: 1fr 0fr;
`;

const Section = styled.section`
  color: #fff;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;

  ${devices.$tabletPortrait} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const BannerHeader = styled.header`
  display: block;

  ${devices.$mobile} {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  img {
    max-height: 45px;
  }
`;

const HeaderLink = styled.a`
  display: flex;

  .ac-header-link-text {
    padding: 5px 0;
    color: white;
    font-size: 14px;
    font-weight: 700;
    font-family: Montserrat;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-right: 10px;
  }

  svg {
    margin-top: 10px;
  }
`;

const Banner = () => {
  const infoItems = [
    {
      icon: location,
      copy: 'Monitor your ActiveCore Network',
      alt: 'location'
    },
    { icon: analytics, copy: 'Get performance details', alt: 'analytics' },
    { icon: info, copy: 'Stay informed', alt: 'info' },
    { icon: activity, copy: 'View alerts and activity', alt: 'activity' }
  ];

  return (
    <BannerGrid>
      <BannerHeader>
        <img src={logo} alt="logo" />
        <HeaderLink
          href="http://activecore.comcast.com"
          alt="Activecore Link"
          target="_blank"
        >
          <div className="ac-header-link-text">Visit Activecore</div>
          <Icon name={EXTERNAL} color="white" />
        </HeaderLink>
      </BannerHeader>
      <Section>
        <BannerContent>
          <Display className="heading">
            ActiveCore<sup>SM</sup> Mobile App
          </Display>
          <SubHeading className="sub-heading">
            Stay connected to your network from anywhere at any time with the
            ActiveCore mobile app.
          </SubHeading>
          <InfoItemGrid>
            {infoItems.map(({ icon, copy, alt }, index) => (
              <InfoItem key={index}>
                <img src={icon} alt={alt} />
                <SubHeading
                  as="span"
                  typography={{
                    fontSize: '1.125rem',
                    fontWeight: 'bold',
                    lineHeight: '1.625rem'
                  }}
                >
                  {copy}
                </SubHeading>
              </InfoItem>
            ))}
          </InfoItemGrid>
        </BannerContent>
        <ImageContent>
          <ImageWrapper>
            <img src={device} alt="mobile" />
          </ImageWrapper>
          <AppStoreLinks>
            <a
              aria-label="Apple Store Link"
              href="https://apps.apple.com/us/app/activecore/id1225215762"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={appStore} alt="App Store" />
            </a>
            <a
              aria-label="Google Store Link"
              href="https://play.google.com/store/apps/details?id=com.comcast.business.sdwan&amp;hl=en_US"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={googlePlay} alt="Google Play" />
            </a>
          </AppStoreLinks>
        </ImageContent>
      </Section>
    </BannerGrid>
  );
};

export default Banner;
