import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

import CarouselControls from './CarouselControls';
import CarouselImageWindow from './CarouselImageWindow';
import Icon from './Icon';

import { Copy, Display } from './typography';
import carouselSlides from './slides';

const CarouselContainer = styled.div`
  background-color: #edefef;
  padding: 20px 40px;
  height: 500px;
`;

const CarouselSection = styled.div`
  padding: 0 20px;
  margin-bottom: 40px;
  background-color: white;
  height: 100%;
`;

const CarouselSlide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
`;

const CarouselPane = styled.div`
  display: flex;
  justify-content: center;
`;

const CarouselMessageWindow = styled.div`
  opacity: 1;
  animation: ${({ isTraversing }) => (isTraversing ? 'fade 1s linear' : '')};
`;

const CarouselMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 80%;
  max-width: 450px;
  margin: 30px 30px 30px 0;

  .slide-message-heading {
    margin: 20px 0 0 0;
    font-size: 30px;
  }

  .slide-message-text {
    flex-direction: column;
    font-size: 16px;
  }

  svg {
    width: 40px;
    height: auto;
  }
`;

// Component
function Carousel(props) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTraversing, setIsTraversing] = useState(false);
  const [hasMadeSelection, setHasMadeSelection] = useState(false);

  /* Left Slides */
  const leftSlides = carouselSlides.map(({ imageProps: { src, alt } }, idx) => (
    <CarouselPane key={`${idx}-left`}>
      <img src={src} alt={alt} />
    </CarouselPane>
  ));

  /* Right Slides */
  const rightSlides = carouselSlides.map(
    ({ iconProps, heading, message }, idx) => (
      <CarouselPane key={`${idx}-right`} style={{ justifyContent: 'left' }}>
        <CarouselMessage>
          <Icon {...iconProps} />
          <Display as="h3" className="slide-message-heading">
            {heading}
          </Display>
          <Copy as="p" className="slide-message-text">
            {message}
          </Copy>
        </CarouselMessage>
      </CarouselPane>
    )
  );

  /* Handlers */
  const handleSlideChange = slideIndex => {
    setIsTraversing(true);
    setCurrentSlide(slideIndex);

    setTimeout(() => {
      setIsTraversing(false);
    }, 1000);
  };

  /* Auto-scroll Carousel */
  useEffect(() => {
    if (!hasMadeSelection) {
      const nextSlide = (currentSlide + 1) % carouselSlides.length;
      const interval = setInterval(() => handleSlideChange(nextSlide), 5000);

      return () => clearInterval(interval);
    }
  }, [currentSlide, hasMadeSelection]);

  return (
    <CarouselContainer>
      <CarouselSection>
        <CarouselSlide>
          <CarouselImageWindow current={currentSlide}>
            {leftSlides}
          </CarouselImageWindow>

          <CarouselMessageWindow isTraversing={isTraversing}>
            {rightSlides[currentSlide]}
          </CarouselMessageWindow>
        </CarouselSlide>

        <CarouselControls
          count={leftSlides.length}
          current={currentSlide}
          onChange={slideIndex => {
            handleSlideChange(slideIndex);

            /* Stop Auto-scroll if user has made selection. Start again after 10 seconds */
            setHasMadeSelection(true);
            setTimeout(() => setHasMadeSelection(false), 10000);
          }}
        />
      </CarouselSection>
    </CarouselContainer>
  );
}

export default Carousel;
