import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Banner from './Banner';
import Carousel from './Carousel';
import MobilePanelSection from './MobilePanelSection';

// Styles
const AppGrid = styled.div`
  display: grid;
  grid-template-rows: repeat(2, minmax(50vh, auto));
`;

const getIsMobile = () => window.matchMedia(`(max-width: 768px)`).matches;

function App() {
  const [isMobile, setIsMobile] = useState(getIsMobile());
  const handleResize = () => setIsMobile(getIsMobile());

  /* Mount & Unmount */
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <AppGrid>
      <Banner />
      {isMobile ? <MobilePanelSection /> : <Carousel />}
    </AppGrid>
  );
}

export default App;
