import styled from "@emotion/styled";

/**
 * @props - {as, typography}
 * @example:
 * <Copy as="span" typography={{ fontFamily, fontWeight, fontSize, lineHeight}} />
 */
export const Copy = styled.p`
  font-family: ${props => props?.typography?.fontFamily || "Lato"};
  font-size: ${props => props?.typography?.fontSize || "1rem"};
  font-weight: ${props => props?.typography?.fontWeight || "normal"};
  line-height: ${props => props?.typography?.lineHeight || "1.5rem"};
`;

/**
 * @props - {as, typography}
 * @example:
 * <Display as="h2" typography={{ fontFamily, fontWeight, fontSize, lineHeight}} />
 */
export const Display = styled.h1`
  font-family: ${props => props?.typography?.fontFamily || "Montserrat"};
  font-size: ${props => props?.typography?.fontSize || "3rem"};
  font-weight: ${props => props?.typography?.fontWeight || "bold"};
  line-height: ${props => props?.typography?.lineHeight || "3.5rem"};
`;

/**
 * @props - {as, typography}
 * @example:
 * <SubHeading as="h4" typography={{ fontFamily, fontWeight, fontSize, lineHeight}} />
 */
export const SubHeading = styled.h2`
  font-family: ${props => props?.typography?.fontFamily || "Lato"};
  font-size: ${props => props?.typography?.fontSize || "1.5rem"};
  font-weight: ${props => props?.typography?.fontWeight || "normal"};
  line-height: ${props => props?.typography?.lineHeight || "2rem"};
`;
