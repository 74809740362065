import React from 'react';

export const ACTIVITY = 'activity';
export const LOCATION = 'location';
export const INFO = 'info';
export const PERFORMANCE = 'performance';
export const EXTERNAL = 'external';

function Icon({ name, color }) {
  if (!name) {
    return null;
  }

  const defaultColor = '#8BE1FA';

  switch (name) {
    case ACTIVITY:
      return (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.15 19.6904L9.56151 2.92494C9.33359 2.24117 8.36641 2.24117 8.13849 2.92494L5.15943 11.8621H1.5C1.08579 11.8621 0.75 12.1979 0.75 12.6121C0.75 13.0263 1.08579 13.3621 1.5 13.3621H5.7C6.02282 13.3621 6.30943 13.1555 6.41151 12.8493L8.85 5.53382L14.4385 22.2993C14.6664 22.9831 15.6336 22.9831 15.8615 22.2993L18.8406 13.3621H22.5C22.9142 13.3621 23.25 13.0263 23.25 12.6121C23.25 12.1979 22.9142 11.8621 22.5 11.8621H18.3C17.9772 11.8621 17.6906 12.0687 17.5885 12.3749L15.15 19.6904Z"
            fill={color || defaultColor}
          />
        </svg>
      );

    case LOCATION:
      return (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9128 2.56738C15.5856 2.56738 18.6186 5.75828 18.6186 9.50037C18.6186 11.5594 17.1278 14.9203 14.1443 19.6862L13.6182 20.517L13.0778 21.3524L12.5375 22.1726C12.2407 22.6192 11.5847 22.6191 11.288 22.1724L10.7476 21.3518C7.05299 15.6912 5.20703 11.7882 5.20703 9.50037C5.20703 5.76191 8.24162 2.56738 11.9128 2.56738ZM11.9128 4.06738C9.08777 4.06738 6.70703 6.57359 6.70703 9.50037C6.70703 11.1355 8.02127 14.1311 10.648 18.3963L10.8981 18.7998L11.4055 19.6044L11.913 20.3923L12.4204 19.6049C15.5518 14.6965 17.1186 11.289 17.1186 9.50037C17.1186 6.57016 14.7397 4.06738 11.9128 4.06738ZM11.9128 6.40081C13.6062 6.40081 14.979 7.77362 14.979 9.46706C14.979 11.1605 13.6062 12.5333 11.9128 12.5333C10.2194 12.5333 8.84656 11.1605 8.84656 9.46706C8.84656 7.77362 10.2194 6.40081 11.9128 6.40081ZM11.9128 7.90081C11.0478 7.90081 10.3466 8.60204 10.3466 9.46706C10.3466 10.3321 11.0478 11.0333 11.9128 11.0333C12.7778 11.0333 13.479 10.3321 13.479 9.46706C13.479 8.60204 12.7778 7.90081 11.9128 7.90081Z"
            fill={color || defaultColor}
          />
        </svg>
      );

    case INFO:
      return (
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.2852 22.6123C17.808 22.6123 22.2852 18.1352 22.2852 12.6123C22.2852 7.08946 17.808 2.6123 12.2852 2.6123C6.76231 2.6123 2.28516 7.08946 2.28516 12.6123C2.28516 18.1352 6.76231 22.6123 12.2852 22.6123ZM12.2852 21.217C7.53294 21.217 3.68051 17.3645 3.68051 12.6123C3.68051 7.86009 7.53294 4.00765 12.2852 4.00765C17.0374 4.00765 20.8898 7.86009 20.8898 12.6123C20.8898 17.3645 17.0374 21.217 12.2852 21.217Z"
            fill={color || defaultColor}
          />
          <path
            d="M12.9813 16.3327V12.6117C12.9813 12.2264 12.6689 11.9141 12.2836 11.9141C11.8983 11.9141 11.5859 12.2264 11.5859 12.6117V16.3327C11.5859 16.718 11.8983 17.0303 12.2836 17.0303C12.6689 17.0303 12.9813 16.718 12.9813 16.3327Z"
            fill={color || defaultColor}
          />
          <path
            d="M11.7903 9.38436C12.0627 9.65682 12.5045 9.65682 12.7769 9.38436C13.0494 9.11191 13.0494 8.67016 12.7769 8.3977C12.5045 8.12524 12.0627 8.12524 11.7903 8.3977C11.5178 8.67016 11.5178 9.11191 11.7903 9.38436Z"
            fill={color || defaultColor}
          />
        </svg>
      );

    case PERFORMANCE:
      return (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.67578 10.5207C3.05548 10.5207 3.36927 10.8028 3.41893 11.1689L3.42578 11.2707V22.031C3.42578 22.4452 3.08999 22.781 2.67578 22.781C2.29609 22.781 1.98229 22.4989 1.93263 22.1328L1.92578 22.031V11.2707C1.92578 10.8565 2.26157 10.5207 2.67578 10.5207ZM7.33762 2.44434C7.71731 2.44434 8.03111 2.72649 8.08077 3.09257L8.08762 3.19434V22.031C8.08762 22.4452 7.75183 22.781 7.33762 22.781C6.95792 22.781 6.64412 22.4989 6.59446 22.1328L6.58762 22.031V3.19434C6.58762 2.78012 6.9234 2.44434 7.33762 2.44434ZM11.9994 10.5207C12.3791 10.5207 12.6929 10.8028 12.7426 11.1689L12.7494 11.2707V22.031C12.7494 22.4452 12.4137 22.781 11.9994 22.781C11.6198 22.781 11.306 22.4989 11.2563 22.1328L11.2494 22.031V11.2707C11.2494 10.8565 11.5852 10.5207 11.9994 10.5207ZM16.6613 7.49165C17.041 7.49165 17.3548 7.77381 17.4044 8.13988L17.4113 8.24165V22.031C17.4113 22.4452 17.0755 22.781 16.6613 22.781C16.2816 22.781 15.9678 22.4989 15.9181 22.1328L15.9113 22.031V8.24165C15.9113 7.82744 16.2471 7.49165 16.6613 7.49165ZM21.3231 13.8136C21.7028 13.8136 22.0166 14.0958 22.0663 14.4619L22.0731 14.5636V22.031C22.0731 22.4452 21.7373 22.781 21.3231 22.781C20.9434 22.781 20.6296 22.4989 20.58 22.1328L20.5731 22.031V14.5636C20.5731 14.1494 20.9089 13.8136 21.3231 13.8136Z"
            fill={color || defaultColor}
          />
        </svg>
      );

    case EXTERNAL:
      return (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.77937 0.617676L10.6678 0.620187C11.0442 0.620307 11.3557 0.897675 11.4094 1.25917L11.4175 1.36995L11.4201 9.38638C11.4202 9.80059 11.0845 10.1365 10.6703 10.1366C10.2938 10.1367 9.98194 9.85933 9.92826 9.49768L9.92009 9.38685L9.9179 3.27368L1.86023 11.3321C1.56733 11.625 1.09246 11.625 0.799565 11.3321C0.531079 11.0637 0.508706 10.6423 0.732444 10.3483L0.799565 10.2715L8.95189 2.11868L2.77889 2.11768C2.40233 2.11756 2.09068 1.83995 2.03722 1.47827L2.02913 1.36744C2.02925 0.990879 2.30685 0.679226 2.66853 0.625772L2.77937 0.617676Z"
            fill={color || defaultColor}
          />
        </svg>
      );

    default:
      return null;
  }
}

export default Icon;
