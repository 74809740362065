import React from 'react';
import styled from '@emotion/styled';
import Icon from './Icon';
import { Copy, Display } from './typography';
import carouselSlides from './slides';

const PanelsContainer = styled.div`
  background-color: #edefef;
  padding: 20px;
  height: 100%;
`;

const Panel = styled.div`
  display: flex;
  justify-content: left;
  background-color: white;
  margin-bottom: 30px;
`;

const PanelMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  margin: 30px;

  .slide-message-heading {
    margin: 20px 0 0 0;
  }

  .slide-message-text {
    flex-direction: column;
    font-size: 16px;
  }

  svg {
    width: 40px;
    height: auto;
  }
`;

// Component
function MobilePanelSection(props) {
  /* Right Slides */
  const panels = carouselSlides.map(({ iconProps, heading, message }, idx) => (
    <Panel key={`${idx}-right`}>
      <PanelMessage>
        <Icon {...iconProps} />
        <Display as="h3" className="slide-message-heading">
          {heading}
        </Display>
        <Copy
          as="p"
          className="slide-message-text"
          typography={{ lineHeight: '24px' }}
        >
          {message}
        </Copy>
      </PanelMessage>
    </Panel>
  ));

  return <PanelsContainer>{panels}</PanelsContainer>;
}

export default MobilePanelSection;
