import { LOCATION, INFO, ACTIVITY, PERFORMANCE } from './Icon';
import dashboardPhoneImage from './assets/dashboardPhoneImage.svg';
import activityPhoneImage from './assets/activityPhoneImage.svg';
import siteDetailsPhoneImage from './assets/siteDetailsPhoneImage.svg';
import sitesPhoneImage from './assets/sitesPhoneImage.svg';

const color = '#1471DA';
const carouselSlides = [
  {
    heading: 'Monitor your network',
    message:
      "Quickly and easily review your ActiveCore SD-WAN's status and key performance indicators on your ActiveCore Dashboard.",
    iconProps: {
      alt: 'Location Icon',
      color,
      name: LOCATION
    },
    imageProps: {
      src: dashboardPhoneImage,
      alt: 'Dashboard page image'
    }
  },
  {
    heading: 'Stay informed',
    message:
      'Customize which events generate push notifications on your mobile device about the status of your ActiveCore network.',
    iconProps: {
      alt: 'Filter Bars',
      color,
      name: INFO
    },
    imageProps: {
      src: activityPhoneImage,
      alt: 'Activity page image'
    }
  },
  {
    heading: 'Get performance details',
    message:
      "Keep an eye on your network's performance, how much data is traveling across your site-to-site VPN circuits, and what applications and services are consuming that data.",
    iconProps: {
      alt: 'Performance Icon',
      color,
      name: PERFORMANCE
    },
    imageProps: {
      src: siteDetailsPhoneImage,
      alt: 'Site Details page image'
    }
  },
  {
    heading: 'View alerts and activity',
    message:
      'Access your complete Alert history from architecture screen. See a timeline of up to 90 days of network events. Filter your results to get a more granular view of events or to see the bigger picture.',
    iconProps: {
      alt: 'Activity Icon',
      color,
      name: ACTIVITY
    },
    imageProps: {
      src: sitesPhoneImage,
      alt: 'Sites page image'
    }
  }
];

export default carouselSlides;
